@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
/* input[type='file'] {
  -webkit-appearance: none;
  text-align: left;
  border: 2px dashed #24abdf;
  border-radius: '6px';
  -webkit-rtl-ordering: left;
}
input[type='file']::-webkit-file-upload-button {
  -webkit-appearance: none;
  float: right;
  border: 1px solid #aaaaaa;
  border-radius: 6px;
  padding: 5px;
  top: 1188px;
  left: 361px;
  width: 176px;
  height: 44px;
  margin-top: -10px;
  margin-right: -10px;
  font-family: 'Poppins';
  color: #ffffff;
  background: #112b3d 0% 0% no-repeat padding-box;
} */
body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, #25aae1 7%, #18bfc5 57%, #0dd1ae 97%);

  border-radius: 16px;
}
.bn-onboard-modal {
  font-family: Roboto;
  z-index: 4;
}
